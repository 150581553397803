import React, { useContext } from 'react'
import { AppContext } from '../../AppContext';

export const StartOverBtn = () => {
    const {
        setQuestionsHistory, setCurrStage,
        setRequirements, setIsRefineStage, setRightStage,
        setCurrTab, setRefineArr, setQuestionsBank, diffHtml, setDiffHtml
    } = useContext(AppContext);

    const stages = [
        "Initial", 
        "IntialThreeQuestions", 
        "ThreeAdditionalQuestions",
        "Refine"
    ]

    // Function to open diffhtml in a new window
    const openDiffHtmlInNewWindow = () => {
        if (diffHtml) {
            const newWindow = window.open("", "_blank");  // Open a new blank window
            newWindow.document.write(diffHtml);  // Write the diffhtml content into the new window
            newWindow.document.close();  // Close the document stream
        }
    };
    
    return (
        <div>
        <button type="button" class="btn btn-primary mx-3" onClick={openDiffHtmlInNewWindow}>What Changed?</button>
        <button type="button" class="btn btn-danger" style={{marginRight:'30px'}}
            onClick={() => {
                setRequirements({
                    requirements: {
                      buyingStage: "Active Looking",
                      sections: [
                        {
                            "sectionTitle": "Executive Summary",
                            "questions": [
                            ],
                            "subsections": [
                            ]
                        },
                        {
                            "sectionTitle": "Evaluation Criteria",
                            "questions": [
                            ],
                            "subsections": [
                            ]
                        }
                      ]
                    },
                });
                setIsRefineStage(false);
                setRightStage("Questions");
                setQuestionsHistory([])
                setQuestionsBank(["Where are you in the buying process?"]);
                setCurrStage("Initial");
                setCurrTab("Questions");
                setRefineArr([]);
            }}
        >
            Start Over
        </button>
        
        </div>
    )
}
