import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [diffHtml, setDiffHtml] = useState("");  // Global state for diffHtml

    const [requirements, setRequirements] = useState({
        requirements: {
          buyingStage: "Active Looking",
          sections: [
            {
                "sectionTitle": "Executive Summary",
                "questions": [
                ],
                "subsections": [
                ]
            },
            {
                "sectionTitle": "Evaluation Criteria",
                "questions": [
                ],
                "subsections": [
                ]
            }
          ]
        },
    });
    const [loading, setLoading] = useState(false);
    const [currTab, setCurrTab] = useState("Questions");
    
    //Questions, Feedback
    const [questionsHistory, setQuestionsHistory] = useState([]); //All the previous answers on the right
    const [questionsBank, setQuestionsBank] = useState(["Where are you in the buying process?"]); //If we are asking questions store them here
    const [rightStage, setRightStage] = useState("Questions"); 
    const [currStage, setCurrStage] = useState("Initial"); //This is only for the intial radio and 6 questions step

    //Refine
    const [isRefineStage, setIsRefineStage] = useState(false); //Do we pop up the refine buttons
    const [refine, setRefine] = useState(""); //The section title we are currently refining
    const [refineArr, setRefineArr] = useState([]); //Stuff the user refined so we don't let them re refine

    //Email, Slack, Message
    const [feedback, setFeedback] = useState({
        email: '', slack: '', role: '', type: '', vendors: []
    })

    return (
        <AppContext.Provider
            value={{
                requirements, setRequirements,
                questionsHistory, setQuestionsHistory,
                refine, setRefine,
                isRefineStage, setIsRefineStage,
                rightStage, setRightStage,
                questionsBank, setQuestionsBank,
                refineArr, setRefineArr,
                currTab, setCurrTab,
                feedback, setFeedback,
                loading, setLoading,
                currStage, setCurrStage,
                diffHtml, setDiffHtml
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
