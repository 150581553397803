import React from 'react';

export const Question = ({ question }) => {
    const options = ["First Thought", "Passive Looking", "Active Looking", "Deciding"];

    return (
        <div
            style={{
                width: '90%', margin: '15px 0px',
                display: 'flex', border: '1px solid grey',
                flexDirection: 'column', padding: '10px',
                alignItems: 'center', justifyContent: 'center',
            }}
        >
            <div
                style={{
                    width: '95%',
                    padding: '10px',
                    boxSizing: 'border-box',
                }}
            >
                {question.title}
            </div>

            {question.isRadio ? (
                <div style={{ width: '95%', margin: '10px 0px' }}>
                    {options.map((option, index) => (
                        <div className="form-check" key={index}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="optionGroup"
                                value={option}
                                id={`flexRadioDefault${index}`}
                                checked={option === question.answer}
                                disabled
                            />
                            <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    style={{
                        width: '95%', margin: '10px 0px', 
                        whiteSpace: 'pre-wrap', // Ensures text wraps properly
                        wordBreak: 'break-word' // Break long words if necessary
                    }}
                >
                    {question.answer}
                </div>
            )}
        </div>
    );
};
