import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { globalTestFlag, globalPromptTestFlag } from '../../../../global';

export const AskingQuestion = ({ }) => {
    const [answer, setAnswer] = useState("");

    const {
        requirements, setRequirements, setIsRefineStage,
        setQuestionsHistory, setRightStage, questionsBank, setQuestionsBank,
        setLoading, currStage, setCurrStage, loading, diffHtml, setDiffHtml
    } = useContext(AppContext);
    const [selectedOption, setSelectedOption] = useState('');
    const options = ["First Thought", "Passive Looking", "Active Looking", "Deciding"]

    /* 
        Clicking on the radio button will show a preview of what it sends so just 
        call the submit for now but don't advance
    */
    const radioPreview = (selectedOption) => {
        if(selectedOption){
            let newReq = requirements;
            console.log("selectedOption", selectedOption)
            newReq.requirements.buyingStage = selectedOption;
            setLoading(true);

            fetch('https://n8n.bleeisblee.com/webhook/initialQuestions', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'appauth': 'qIndud-xufmak-9qofca' 
                },
                body: JSON.stringify(newReq)
            })
            .then(response => response.json())
            .then(data => {
                setRequirements(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
            });
        }
    }
    const handleOptionChange = (option) => {
        setSelectedOption(option);
        radioPreview(option);
    };

    //Submit the answer
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();  
            submit();
        }
    };
    
    const submit = async () => {
        if(currStage === "Initial"){
            await initalReq();
        } 
        else if(currStage === "IntialThreeQuestions"){
            await IntialThreeQuestionsReq()
        }
        else if(currStage === "ThreeAdditionalQuestions"){
            await ThreeAdditionalQuestionsReq()
        }
        else {
            console.log("No more stages")
        }
    }

    const initalReq = async () => {
        if(selectedOption){
            let newReq = requirements;
            newReq.requirements.buyingStage = selectedOption;
            setLoading(true);

            fetch('https://n8n.bleeisblee.com/webhook/initialQuestions', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'appauth': 'qIndud-xufmak-9qofca' 
                },
                body: JSON.stringify(newReq)
            })
            .then(response => response.json())
            .then(data => {
                setCurrStage("IntialThreeQuestions");
                setRequirements(data);
                setQuestionsHistory(p => [...p, {
                    title: questionsBank[0], answer: selectedOption, isRadio:true
                }])

                let newQuestions = data.requirements.sections[0].questions.map(q => q.question);
                setQuestionsBank(newQuestions)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
            });
        }
    }

    const putAnswerInRequirementsObject = () => {
        let newReq = requirements;
        for(let section of newReq.requirements.sections){
            for(let q of section.questions){
                if(q.question === questionsBank[0]){
                    q.answer = answer;
                    q.isNew = true;
                    setQuestionsHistory(p => [...p, {
                        title: questionsBank[0], answer: answer, isRadio:false
                    }])
                    break;
                }
            }
        }

        return newReq;
    }
    const IntialThreeQuestionsReq = async () => {
        if(answer.trim() === "") return;
        const newReq = putAnswerInRequirementsObject();
        setRequirements(newReq);

        // Get the next question
        if(questionsBank.length-1 > 0){
            setQuestionsBank(p => p.slice(1, p.length));
        } 
        //This means we asked all the questions and we make the next call
        else {
            setLoading(true);
            fetch('https://n8n.bleeisblee.com/webhook/requirements', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'appauth': 'qIndud-xufmak-9qofca' 
                },
                body: JSON.stringify({action: "initialSummary", test: globalTestFlag, promptTest: globalPromptTestFlag, ...newReq})
            })
            .then(response => response.json())
            .then(data => {
                console.log('ThreeAdditionalQuestions: ', data);
                setCurrStage("ThreeAdditionalQuestions");
                setRequirements(data);

                let newQuestions = data.requirements.sections[0].questions.map(q => q.question);
                setQuestionsBank(newQuestions.slice(3,7))
                setLoading(false);
                setDiffHtml(data.diffhtml || "");
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
            });
        }

        //Reset the text field
        setAnswer("");
    }
    const ThreeAdditionalQuestionsReq = async () => {
        if(answer.trim() === "") return;
        const newReq = putAnswerInRequirementsObject();
        setRequirements(newReq);

         // Get the next question
        if(questionsBank.length-1 > 0){
            setQuestionsBank(p => p.slice(1, p.length));
        } 
         //This means we asked all the questions and we make the next call
         else {
            setLoading(true);
            fetch('https://n8n.bleeisblee.com/webhook/requirements', {
                 method: 'POST', 
                 headers: {
                     'Content-Type': 'application/json',
                     'appauth': 'qIndud-xufmak-9qofca' 
                 },
                 body: JSON.stringify({action: "regenerate", test: globalTestFlag, promptTest: globalPromptTestFlag, ...newReq})
            })
             .then(response => response.json())
             .then(data => {
                 setCurrStage("Refine");
                 setRequirements(data);
                 console.log(data)
                 
                 setIsRefineStage(true);
                 setQuestionsBank([]);
                 setRightStage("Feedback")
                 setLoading(false);
                 setDiffHtml(data.diffhtml || "");

            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);
            });
         }
 
         setAnswer("");
    }
    // Function to open diffhtml in a new window
    const openDiffHtmlInNewWindow = () => {
        if (diffHtml) {
            const newWindow = window.open("", "_blank");  // Open a new blank window
            newWindow.document.write(diffHtml);  // Write the diffhtml content into the new window
            newWindow.document.close();  // Close the document stream
        }
    };

    if(!loading) return (
        <form style={{margin:'20px 20px', width:'100%'}}>
            <label 
                for="exampleInputEmail1" class="form-label"
                style={{ width: '100%', display: 'block' }}
            >
                {questionsBank[0]}
            </label>
            
            {currStage === "Initial" ? 
                <div class="mb-3">
                    {options.map((option, index) => (
                        <div className="form-check" key={index}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="optionGroup"  // All radio buttons in this group need the same name
                                value={option}
                                id={`flexRadioDefault${index}`}
                                checked={selectedOption === option}
                                onChange={() => handleOptionChange(option)}
                            />
                            <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
                    :
                <div class="mb-3">
                    <textarea 
                        class="form-control" id="exampleFormControlTextarea1" rows="3"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        onKeyDown={handleKeyDown}
                    >
                    </textarea>
                    <div id="emailHelp" class="form-text">Enter answer here</div>
                </div>
            }

            <button type="button" class="btn btn-primary" onClick={() => submit()}>
                Submit
            </button>
        </form>
    );

    return (
        <div style={{height:'30px'}}>
        </div>
    )
};
