import React from 'react'

export const Btn = ({
    width, height, func, color, text
}) => {
    return (
        <div style={{
            padding: '10px 20px',  // Add horizontal padding to control box width
            background: color,
            cursor: 'pointer',
            borderRadius: 5,
            display: 'inline-flex',
            marginTop:'auto',
            justifyContent: 'center',
            alignItems: 'center'
        }} 
        onClick={() => func()}>
            <h6 style={{ color: '#fff', margin: 0 }}>{text}</h6>  {/* Ensure no margin */}
        </div>
        
    )
}
