import React, { useContext, useState, useRef, useEffect } from 'react'
import { AppContext } from '../../../AppContext';
import { AskingQuestion } from './HighlightedBox/AskingQuestion';
import { AskUserToRefine } from './HighlightedBox/AskUserToRefine';
import { RefineQuestion } from './HighlightedBox/RefineQuestion';
import { Question } from './Question';

export const QuestionsPage = () => {
    const {questionsHistory, rightStage, refine, questionsBank} = useContext(AppContext);

    const [allQuestions, setAllQuestions] = useState([]);

    // Scroll to the bottom whenever questionsHistory updates
    const bottomRef = useRef(null);
    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [questionsHistory, questionsBank]);

    return (
        <div style={{width:'100%', }}>
            <div style={{ 
                width: '100%', marginTop: 'auto',display:'flex', alignItems:'center', 
                flexDirection:'column'
            }}>
                {questionsHistory.map((q, i) => (
                    <Question question={q} key={i} />
                ))}
            </div>

            {/* A div at the bottom to ensure auto-scroll happens */}
            <div ref={bottomRef}></div>

            {rightStage === "Questions" &&
                <div style={{ width: '100%', marginTop: 'auto',display:'flex',   justifyContent:'center',}}>
                    <AskingQuestion
                        allQuestions={allQuestions}
                        setAllQuestions={setAllQuestions}
                    />
                </div>
            }

            {(rightStage === "Feedback"  && refine === "") &&
                <div style={{ width: '100%', marginTop: 'auto', display:'flex', justifyContent:'center' }}>
                    <AskUserToRefine />
                </div>
            }

            {(rightStage === "Feedback"  && refine !== "") &&
                <div style={{ width: '100%', marginTop: 'auto', display:'flex',justifyContent:'center' }}>
                    <RefineQuestion />
                </div>
            }
        </div>
    )
}
