import React, { useContext } from 'react'
import { AppContext } from '../../AppContext'

export const Section = ({title, subsections}) => {
    const {
        refine, setRefine, isRefineStage, setQuestionsBank, requirements,
        refineArr, setCurrTab, setRightStage,
    } = useContext(AppContext);

    return (
        <div style={{margin:'40px 40px', marginTop:'30px'}}>

            <div style={{
                display:'flex', width:'90%', display:'flex', justifyContent:'space-between',
                alignItems:'center',
            }}>
                <h2>{title}</h2>

                {(isRefineStage && refine === "" && !refineArr.find(i => i === title)) &&
                    <RefineBtn 
                        type={"Default"}
                        func={() => {
                        const unansweredQuestions = requirements.requirements.sections
                            .find(section => section.sectionTitle === title)
                            .questions
                            .filter(q => q.answer === "")
                            .map(q => q.question); 
                        setQuestionsBank(unansweredQuestions);
                        setCurrTab("Questions");
                        setRefine(title);
                    }} />
                }

                {(isRefineStage && refine === title && !refineArr.find(i => i === title) ) &&
                    <RefineBtn 
                        type={"Unpick"}
                        func={() => {
                        setRightStage("Feedback")
                        setQuestionsBank([]);
                        setRefine("")
                    }} />
                }

                {(isRefineStage && refineArr.find(i => i === title) ) &&
                    <RefineBtn 
                        type={"Picked"}
                        func={() => {
                    }} />
                }
            </div>

            {subsections.map((s,i) => 
                <div key={i} style={{marginBottom:'25px'}}>
                    <ul>
                        <li>
                            <h4>{s.title}</h4> 
                                <ul>
                                    {s.items.map((item, id) => (
                                        <div style={{display:'flex', marginLeft:'-20px',width:'90%',}}>
                                            {item.isNew ? 
                                                <>
                                                    <div style={{minWidth: '10px', width:'10%'}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 256 256">
                                                            <path fill="#6E2CF4" d="m199 125.31l-49.88-18.39L130.69 57a19.92 19.92 0 0 0-37.38 0l-18.39 49.92L25 125.31a19.92 19.92 0 0 0 0 37.38l49.88 18.39L93.31 231a19.92 19.92 0 0 0 37.38 0l18.39-49.88L199 162.69a19.92 19.92 0 0 0 0-37.38m-63.38 35.16a12 12 0 0 0-7.11 7.11L112 212.28l-16.47-44.7a12 12 0 0 0-7.11-7.11L43.72 144l44.7-16.47a12 12 0 0 0 7.11-7.11L112 75.72l16.47 44.7a12 12 0 0 0 7.11 7.11l44.7 16.47ZM140 40a12 12 0 0 1 12-12h12V16a12 12 0 0 1 24 0v12h12a12 12 0 0 1 0 24h-12v12a12 12 0 0 1-24 0V52h-12a12 12 0 0 1-12-12m112 48a12 12 0 0 1-12 12h-4v4a12 12 0 0 1-24 0v-4h-4a12 12 0 0 1 0-24h4v-4a12 12 0 0 1 24 0v4h4a12 12 0 0 1 12 12" />
                                                        </svg>
                                                    </div>

                                                    <li style={{width:'90%'}} key={id}>{item.itemDescription}</li>  
                                                </>
                                                    :
                                                <>
                                                    <div style={{minWidth: '10px', width:'10%'}}></div>
                                                    <li style={{width:'90%'}} key={id}>{item.itemDescription}</li>  
                                                </>
                                            }

                                        </div>
                                    ))}
                                </ul>
                        </li>
                    </ul>
                </div>    
            )}

            {subsections.length === 0 &&
                <>
                    <h5 class="card-title">
                        <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text ">
                        {/* <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span> */}
                    </p>    
                </>
            }
        </div>
    )
}

const RefineBtn = ({func, type}) => {
    if(type === "Default") return(
        <div style={{
                width:'100px', height:'40px', cursor:'pointer',
                border: '2px solid #c8c3fb', borderRadius:5, 
                display:'flex', justifyContent:'space-evenly', alignItems:'center'
            }}
            onClick={() => func()}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32">
                <path fill="#6557F5" d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8zm24-8h-6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2m-6-8v6h6V4z" />
                <path fill="#6557F5" d="M24 26h-8v-2h8v-7H8a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h10v2H8v7h16a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2" />
            </svg>
           <h6 style={{color:'#6557F5', margin:0}}>Refine</h6> 
        </div>
    )

    if(type === "Picked") return(
        <div style={{
                width:'150px', height:'40px', background:'#6557F5',
                border: '2px solid #c8c3fb', borderRadius:5, 
                display:'flex', justifyContent:'space-evenly', alignItems:'center'
            }}
            onClick={() => func()}
        >
           <h6 style={{color:'#fff', margin:0}}>Completed</h6> 
        </div>
    )

    if(type === "Unpick") return(
        <div style={{
                width:'100px', height:'40px', cursor:'pointer',
                border: '2px solid #c8c3fb', borderRadius:5, 
                display:'flex', justifyContent:'space-evenly', alignItems:'center'
            }}
            onClick={() => func()}
        >
           <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 15 15">
                <path fill="#6557F5" d="M3.64 2.27L7.5 6.13l3.84-3.84A.92.92 0 0 1 12 2a1 1 0 0 1 1 1a.9.9 0 0 1-.27.66L8.84 7.5l3.89 3.89A.9.9 0 0 1 13 12a1 1 0 0 1-1 1a.92.92 0 0 1-.69-.27L7.5 8.87l-3.85 3.85A.92.92 0 0 1 3 13a1 1 0 0 1-1-1a.9.9 0 0 1 .27-.66L6.16 7.5L2.27 3.61A.9.9 0 0 1 2 3a1 1 0 0 1 1-1c.24.003.47.1.64.27" />
            </svg>
           <h6 style={{color:'#6557F5', margin:0}}>Unselect</h6> 
        </div>
    )

}