import React, {useContext, useState} from 'react';
import { AppContext } from '../../AppContext';
import { Tab } from './Tab';
import {Btn} from '../Btn'
import { globalTestFlag, globalPromptTestFlag } from '../../global';

export const FeedbackOverlay = () => {
    const tabs = ["Email", "Slack", "Submit Feedback"];
    const [cTab, setCTab] = useState(tabs[0]);
    const [answer, setAnswer] = useState("");

    const {feedback, setFeedback, requirements, setLoading} = useContext(AppContext);

    return (
        <div className='overlay'>
            <div className='overlayBox'>
                <div style={{display:'flex', marginBottom:'20px'}}>
                    {tabs.map((tab,i) =>
                        <ul class="nav nav-underline">
                            <Tab 
                                key={i} tab={tab} selected={cTab === tab} 
                                setCTab={setCTab}
                            />
                        </ul>
                    )}
                </div>

                {cTab === "Email" && <div style={{ whiteSpace: 'pre-line' }}>{feedback.email}</div>}
                {cTab === "Slack" && <div style={{ whiteSpace: 'pre-line' }}>{feedback.slack}</div>}
                {cTab === "Submit Feedback" && 
                    <div class="mb-3">
                        <textarea 
                            class="form-control" id="exampleFormControlTextarea1" rows="6"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                        >
                        </textarea>
                        <div id="emailHelp" class="form-text">Enter answer here</div>
                    </div>
                }

                <div style={{height:'25px'}} />

                <div style={{display:'flex'}}>
                    {cTab === "Submit Feedback" &&
                        <Btn 
                            color="#6558f5" 
                            func={() => {
                                const requirementsParam = {
                                    action: "regenerateWithFeedback", 
                                    role: feedback.role,
                                    test: globalTestFlag,
                                    promptTest: globalPromptTestFlag,
                                    feedback: answer,
                                    ...requirements,
                                }
                                setLoading(true);
                                console.log(requirementsParam)
                                fetch('https://n8n.bleeisblee.com/webhook/requirements', {
                                    method: 'POST', 
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'appauth': 'qIndud-xufmak-9qofca' 
                                    },
                                    body: JSON.stringify(requirementsParam)
                                })
                                .then(response => response.json())
                                .then(data => {
                                    console.log('Feed back ', data);
                                    setFeedback({
                                        email: "", slack: "", role: "", vendors: []
                                    })
                                    setLoading(false);
                                })
                                .catch(error => {
                                    setLoading(false);
                                    console.error('Error:', error);
                                });
                            }} 
                            text="Submit Feedback"
                        />
                    }
                    {cTab === "Submit Feedback" && <div style={{width:'10px'}} />}

                    <Btn 
                        color="grey" 
                        func={() => {
                            setFeedback({ email: '', slack: '', role: '', type: '', vendors: []})
                        }} 
                        text="Close"
                    />
                </div>
            </div>
        </div>
    );
}
