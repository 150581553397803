import React, {useContext, useState} from 'react';
import { AppContext } from '../../AppContext';
import { Btn } from '../Btn';
import { Tab } from './Tab';

export const ActOverlay = () => {
    const tabs = ["Email", "Slack"];
    const [cTab, setCTab] = useState(tabs[0]);
    const [answer, setAnswer] = useState("");

    const {feedback, setFeedback, requirements} = useContext(AppContext);

    return (
        <div className='overlay'>
            <div className='overlayBox'>
                
                <div style={{display:'flex', marginBottom:'20px'}}>
                    {tabs.map((tab,i) =>
                        <ul class="nav nav-underline">
                            <Tab 
                                key={i} tab={tab} selected={cTab === tab} 
                                setCTab={setCTab}
                            />
                        </ul>
                    )}
                </div>

                {cTab === "Email" && <div style={{ whiteSpace: 'pre-line' }}>{feedback.email}</div>}
                {cTab === "Slack" && <div style={{ whiteSpace: 'pre-line' }}>{feedback.slack}</div>}

                <div style={{height:'25px'}} />
                <Btn 
                    color="#6558f5" 
                    func={() => {setFeedback({ email: '', slack: '', role: '', type: '', vendors: []})}} 
                    text="Close"
                />
            </div>
        </div>
    );
}
