import React, { useContext } from 'react'
import { AppContext } from '../../../AppContext';
import { globalTestFlag, globalPromptTestFlag } from '../../../global';

export const ActPage = () => {
    const names = ["Vendor Meeting", "Infosec Assessment", "AI Governance", "Finance Approval", "Engineering Review"];

    return (
        <>
            <div style={{textAlign:'center', marginBottom:'25px'}}>
               What's next? Here are common activities with a completed BRD
            </div>

            {names.map((name, i) => <Btn key={i} name={name} />)}
        </>
    )
}

const Btn = ({name}) => {
    const {setFeedback, requirements, setLoading} = useContext(AppContext);

    const VendorMeeting = () => {
        setLoading(true)
        fetch('https://n8n.bleeisblee.com/webhook/vendorRecommendations', {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'appauth': 'qIndud-xufmak-9qofca' 
            },
            body: JSON.stringify({
                ...requirements,
            })
        })
        .then(response => response.json())
        .then(data => {
            setFeedback({
                role: name,
                vendors: data.vendors,
                type: 'Vendor'
            })
            setLoading(false)
        })
        .catch(error => {
            setLoading(false)
            console.error('Error:', error);
        });
    }

    return (
        <div style={{
            width:'200px', height:'40px', marginBottom:'10px',
            display:'flex', alignItems:'center', justifyContent:'center', color:'#6558f5',
            cursor:'pointer', borderRadius:10, border:'1px solid #6558f5'
        }} onClick={() => {
            if(name === "Vendor Meeting"){
                VendorMeeting()
            } else {
                setLoading(true)
                fetch('https://n8n.bleeisblee.com/webhook/message', {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                        'appauth': 'qIndud-xufmak-9qofca' 
                    },
                    body: JSON.stringify({
                        action: "communicate", 
                        role: name,
                        test:globalTestFlag,
                        promptTest: globalPromptTestFlag,
                        ...requirements,
                    })
                })
                .then(response => response.json())
                .then(data => {
                    setFeedback({
                        email: data.email,
                        slack: data.slack,
                        role: name,
                        type: 'Act'
                    })
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    console.error('Error:', error);
                });
            }
        }}>
            {name}
        </div>
    )
}
