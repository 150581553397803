import React, {useContext, useEffect, useState} from 'react';
import { AppContext } from '../../AppContext';
import { Btn } from '../Btn';
import { Tab } from './Tab';

export const VendorOverlay = () => {
    const tabs = ["Matching", "Communicate"];
    const [cTab, setCTab] = useState(tabs[0]);
    const [answer, setAnswer] = useState('');
    const [email, setEmail] = useState('');

    const {feedback, setFeedback, requirements} = useContext(AppContext);

    return (
        <div className='overlay'>
            <div className='overlayBox'>

                <div style={{display:'flex', marginBottom:'20px'}}>
                    {tabs.map((tab,i) =>
                        <ul class="nav nav-underline">
                            <Tab 
                                key={i} tab={tab} selected={cTab === tab} 
                                setCTab={setCTab}
                            />
                        </ul>
                    )}
                </div>

                {cTab === "Matching" && <div>
                    {feedback.vendors.map((vendor, i) =>
                        <Vendor
                            name={vendor.name}
                            description={vendor.description}
                            match={vendor.match}
                            key={i}
                        />
                    )}    
                </div>}

                {cTab === "Communicate" && 
                    <Text email={email} setEmail={setEmail}/>
                }

                <div style={{height:'25px'}} />
                <Btn 
                    color="#6558f5" 
                    func={() => {setFeedback({ email: '', slack: '', role: '', type: '', vendors: []})}} 
                    text="Close"
                />

            </div>
        </div>
    );
}

const Vendor = ({name, description, match}) => {
    return(
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop:'15px' }}>
                <h3 style={{ margin: 0, display: 'inline-block' }}>{name}</h3>  
                <p style={{ margin: 0, display: 'inline-block', marginLeft: '10px' }}>{match}%</p>
            </div>

            <p style={{margin:0, marginTop:'5px'}}>{description}</p>
        </div>
    )
}

const Text = ({email, setEmail}) => {
    const {requirements, setLoading} = useContext(AppContext);

    useEffect(() => {
        if(email){
        } else {
            setLoading(true)
            fetch('https://n8n.bleeisblee.com/webhook/message', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'appauth': 'qIndud-xufmak-9qofca' 
                },
                body: JSON.stringify({
                    role: "Vendor Meeting",
                    action: "communicate", 
                    ...requirements,
                })
            })
            .then(response => response.json())
            .then(data => {
                setLoading(false)
                console.log(data.email)
                setEmail(data.email)
            })
            .catch(error => {
                setLoading(false)
                console.error('Error:', error);
            });
        }
    }, [])

    return(
        <div style={{ whiteSpace: 'pre-line' }}>
            {email}
        </div>
    )
}
