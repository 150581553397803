import './App.css';
import { AppProvider } from './AppContext'; // Import the provider
import { Body } from './components/Body';
import { StartOverBtn } from './components/Top/StartOverBtn';

const App = () => {
    return (
        <AppProvider>
            <div className="App">
              <div style={{
                  width:'100%', height:'10%', display:'flex',
                  justifyContent:'flex-end', alignItems:'center'
              }}>
                <StartOverBtn />
              </div>

              <Body />
            </div>
        </AppProvider>
    );
};

export default App;
