import React, { useContext } from 'react'
import { AppContext } from '../../../AppContext';
import { globalTestFlag, globalPromptTestFlag } from '../../../global';

export const FeedbackPage = () => {
    const names = ["Engineering", "Legal", "Security", "Finance"];

    return (
        <div style={{width:"100%"}}>
            <div style={{margin:'10px 20px 10px 20px'}}>
                We can help you solict feedback from your organization
            </div>
            <div style={{margin:'0px 20px'}}>
               Below are teams we suggest reaching out to. We can 
               help you craft a message to solict feedback from these teams.
            </div>

            <div style={{
                display:'flex', alignItems:'center', flexDirection:'column',
                marginTop:'20px'
            }}>
                {names.map((name, i) => <Btn key={i} name={name} />)}

                <Btn name="What else"/>
            </div>
        </div>
    )
}

const Btn = ({name}) => {
    const {setFeedback, requirements, setLoading} = useContext(AppContext);

    return (
        <div style={{
            width:'200px', height:'40px', marginBottom:'10px',
            display:'flex', alignItems:'center', justifyContent:'center', color:'#1aae9f',
            cursor:'pointer', borderRadius:10, border:'1px solid #1aae9f'
        }} onClick={() => {
            setLoading(true);
            fetch('https://n8n.bleeisblee.com/webhook/message', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'appauth': 'qIndud-xufmak-9qofca' 
                },
                body: JSON.stringify({
                    action: "feedback", 
                    role: name,
                    test: globalTestFlag,
                    promptTest: globalPromptTestFlag,
                    ...requirements,
                })
            })
            .then(response => response.json())
            .then(data => {
                setFeedback({
                    email: data.email,
                    slack: data.slack,
                    role: name,
                    type:'Feedback'
                })
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
            });
        }}>
            {name}
        </div>
    )
}
