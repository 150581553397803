import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../../../AppContext';

export const RefineQuestion = ({ }) => {
    const {
        requirements, setRequirements, setQuestionsHistory, questionsBank,
        setRefineArr, refine, setRefine, setQuestionsBank, loading,questionsHistory
    } = useContext(AppContext);
    const [answer, setAnswer] = useState("");

    const bottomRef = useRef(null);
    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [questionsHistory]);

    const putAnswerInRequirementsObject = (requirements) => {
        let newReq = requirements;
        for(let section of newReq.requirements.sections){
            for(let q of section.questions){
                if(q.question === questionsBank[0]){
                    q.answer = answer;
                    q.isNew = true;
                    setQuestionsHistory(p => [...p, {
                        title: questionsBank[0], answer: answer, isRadio:false
                    }])
                    break;
                }
            }
        }

        return newReq;
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();  
            send();
        }
    };
    const send = () => {
        if(answer.trim() === "") return;

        const newReq = putAnswerInRequirementsObject(requirements);
        setRequirements(newReq);

        if(questionsBank.length-1 > 0){
            //Get next question
            setQuestionsBank(p => p.slice(1, p.length));
        } else {
            setRefineArr(p => [...p, refine])
            setRefine("")
        }

        setAnswer('')
    }
    const skip = () => {
        console.log('skipped')
        //Get next question
        if(questionsBank.length-1 > 0){
            setQuestionsBank(p => p.slice(1, p.length));
        } else {
            setRefineArr(p => [...p, refine])
            setRefine("")
        }

        setAnswer('')
    }

    if(!loading) return (
        <form style={{margin:'20px 20px', width:'100%'}}>
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">{questionsBank[0]}</label>
                <textarea 
                    class="form-control" id="exampleFormControlTextarea1" rows="3"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    onKeyDown={handleKeyDown}
                >
                </textarea>
                <div id="emailHelp" class="form-text">Enter answer here</div>
            </div>

            <button type="button" class="btn btn-primary" onClick={() => send()}>
                Submit
            </button>
            <button style={{marginLeft:'10px'}} type="button" class="btn btn-info" onClick={() => skip()}>
                Skip
            </button>
        </form>
    );

    return (
        <div style={{height:'30px'}}>
        </div>
    )
};
