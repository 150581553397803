import React from 'react';

export const Tab = ({ tab, selected, setCTab, disabled }) => {
    if(disabled) return (
        <li className="nav-item">
            <a
                className={`nav-link disabled`}
                onClick={() => setCTab(tab)}
                style={{
                    marginRight:'10px'
                }}
            >
                {tab}
            </a>
        </li>
    )
    return (
        <li className="nav-item">
            <a
                className={`nav-link ${selected ? 'active' : ''}`}
                onClick={() => setCTab(tab)}
                style={{
                    cursor: 'pointer',
                    color: selected ? '#6558f5' : 'grey',
                    marginRight:'10px'
                }}
            >
                {tab}
            </a>
        </li>
    );
};