import React, { useContext, useState, useEffect } from 'react'
import {Left} from './Left/Left'
import {Right} from './Right/Right'
import {LoadingOverlay} from './Overlay/Loading';
import { AppContext } from '../AppContext';
import Cookies from 'js-cookie';

export const Body = () => {
    const {
        loading, requirements, isRefineStage, rightStage, currStage,
        questionsBank,
        questionsHistory, setQuestionsHistory, setQuestionsBank,
        setRequirements, setIsRefineStage, setRightStage, setCurrStage,
    } = useContext(AppContext);
    const [cookieLoaded, setCookieLoaded] = useState(false);

    //Load the cookies
    useEffect(() => {
        const savedIsRefineStage = localStorage.getItem('isRefineStage');
        const savedRightStage = localStorage.getItem('rightStage');
        const savedCurrStage = localStorage.getItem('currStage');
        const savedRequirements = localStorage.getItem('requirements');
        const savedQuestionsHistory = localStorage.getItem('questionsHistory');
        const savedQuestionsBank = localStorage.getItem('questionsBank');

        // console.log("Intial Load")
        // console.log("Requirements", savedRequirements)
        // console.log("isRefineStage", savedIsRefineStage)
        // console.log("right Stage", savedRightStage)
        // console.log("questionsHistory", questionsHistory)

        if (savedRequirements) setRequirements(JSON.parse(savedRequirements));
        if (savedIsRefineStage) setIsRefineStage(JSON.parse(savedIsRefineStage));
        if (savedQuestionsBank) setQuestionsBank(JSON.parse(savedQuestionsBank));
        if (savedQuestionsHistory) setQuestionsHistory(JSON.parse(savedQuestionsHistory));
        if (savedRightStage) setRightStage(savedRightStage);
        if (savedCurrStage) setCurrStage(savedCurrStage);

        if (!savedRequirements) localStorage.setItem('requirements', JSON.stringify({
            requirements: {
                buyingStage: "Active Looking",
                sections: []
            },
          }
        ));
        if (!savedIsRefineStage) localStorage.setItem('isRefineStage', JSON.stringify(false));
        if (!savedQuestionsBank) localStorage.setItem('questionsBank', JSON.stringify([]));
        if (!savedQuestionsHistory) localStorage.setItem('questionsHistory', JSON.stringify([]));
        if (!savedRightStage) localStorage.setItem('rightStage', rightStage);
        if (!savedCurrStage) localStorage.setItem('currStage', currStage);
        setCookieLoaded(true);
    }, []);

    useEffect(() => {
        if(cookieLoaded){
            console.log("requirements cookie is saved", requirements)
            localStorage.setItem('requirements', JSON.stringify(requirements));
            localStorage.setItem('isRefineStage', JSON.stringify(isRefineStage));
            localStorage.setItem('questionsHistory', JSON.stringify(questionsHistory));
            localStorage.setItem('questionsBank', JSON.stringify(questionsBank));
            localStorage.setItem('rightStage', rightStage); // No need to stringify
            localStorage.setItem('currStage', currStage); // No need to stringify
        }
    }, [requirements, isRefineStage, rightStage, questionsHistory, currStage, questionsBank]);

    if(cookieLoaded) return (
        <div style={{width:'100%', height:'90%', borderTop:'3px solid grey', display:'flex'}}>
            <Left />
            <Right />
            
            {loading && <LoadingOverlay /> }
        </div>
    )
}