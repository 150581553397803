import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';

export const AskUserToRefine = ({}) => {
    const {
        refineArr, setRefineArr, setRequirements, requirements, setQuestionsBank,
        setLoading
    } = useContext(AppContext);
    const [diffHtml, setDiffHtml] = useState("");  // State for diffhtml

    // Function to open diffhtml in a new window
    const openDiffHtmlInNewWindow = () => {
        if (diffHtml) {
            const newWindow = window.open("", "_blank");  // Open a new blank window
            newWindow.document.write(diffHtml);  // Write the diffhtml content into the new window
            newWindow.document.close();  // Close the document stream
        }
    };

    return (
        <div
            style={{
                width: '90%', marginTop:'25px',
                display: 'flex', border:'1px solid grey', marginBottom:'10px',
                flexDirection: 'column', padding:'10px', background:'#ced8e0',
                alignItems: 'center', justifyContent: 'center',
            }}
        >
            {refineArr.length > 0 ?
                <div
                    style={{
                        width: '95%',
                        maxHeight: '300px',
                        overflowY: 'auto',
                        padding: '10px',
                        boxSizing: 'border-box',
                    }}
                >
                    <p>
                        All done editing? Ready to regenerate your
                        BRD with all the feedback now. If not 
                        keep editing and answering questions.
                    </p>

                    <button 
                        style={{
                            width:'50px', height:'50px', display:'flex',
                            border:'1px solid #fff', borderRadius:5, cursor:'pointer',
                            justifyContent:'center', alignItems:'center', background:'#1f7968'
                        }}
                        onClick={() => {
                            console.log("This is user generated requirements",requirements)
                            setLoading(true);
                            fetch('https://n8n.bleeisblee.com/webhook/requirements', {
                                method: 'POST', 
                                headers: {
                                    'Content-Type': 'application/json',
                                    'appauth': 'qIndud-xufmak-9qofca' 
                                },
                                body: JSON.stringify({
                                    action: "regenerate", 
                                    // action:'regenerateWithFeedback',
                                    ...requirements
                                })
                            })
                            .then(response => response.json())
                            .then(data => {
                                setRequirements(data);
                                console.log(data)
                                setRefineArr([]);
                                setQuestionsBank([]);
                                setLoading(false);
                                setDiffHtml(data.diffhtml || "");
                            })
                            .catch(error => {
                                setLoading(false)
                                console.error('Error:', error);
                            });
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 20 20">
                            <g fill="#fff">
                                <path d="M13.937 4.211a1 1 0 0 1-1.126 1.653A5 5 0 1 0 15 10a1 1 0 1 1 2 0a7 7 0 1 1-3.063-5.789" />
                                <path d="M13.539 12.506a1 1 0 1 1-1.078-1.685l3.482-2.227a1 1 0 0 1 1.077 1.685z" />
                                <path d="M18.903 12.41a1 1 0 0 1-1.826.815l-1.508-3.38a1 1 0 1 1 1.826-.815z" />
                            </g>
                        </svg>
            <button type="button" class="btn btn-primary mx-3" onClick={openDiffHtmlInNewWindow}>What Changed?</button>
                    </button> 
                </div>
                    :
                    <div
                    style={{
                        width: '95%',
                        maxHeight: '300px', // Fixed height for the text box
                        overflowY: 'auto', // Scroll if content exceeds 300px
                        padding: '10px',
                        boxSizing: 'border-box',
                    }}
                >
                    We have generated your requirements! Start to refine your document on the Left or if it looks good move on with the Feedback
                </div>
            }
            
        </div>
    );
};