import React from 'react'


export const LoadingOverlay = () => {
    return (
        <div className='overlay'>
            <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}
