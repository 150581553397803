import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import { FeedbackOverlay } from '../Overlay/FeedbackOverlay';
import { VendorOverlay } from '../Overlay/VendorOverlay';
import { ActOverlay } from '../Overlay/ActOverlay';
import { ActPage } from './ActPage/ActPage';
import { FeedbackPage } from './Feedback/FeedbackPage';
import { QuestionsPage } from './Questions/QuestionsPage';
import { Tab } from '../Overlay/Tab';

export const Right = ({}) => {
    const {feedback, currTab, setCurrTab, isRefineStage} = useContext(AppContext);

    const tabs = ["Questions", "Feedback", "Act"];
    
    return (
        <div style={{
            width: '35%', height: '100%', borderLeft: '2px solid grey',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-center',
            alignItems: 'center', overflowY: 'auto'
        }}>
            
            {isRefineStage ?
                <div style={{ display:'flex', marginBottom:'20px'}}>
                    {tabs.map((tab, i) => 
                        <ul class="nav nav-underline">
                            <Tab tab={tab} selected={currTab === tab} key={i} setCTab={setCurrTab}/>
                        </ul>
                    )}
                </div>
                    :
                <div style={{display:'flex', marginBottom:'20px'}}>
                    <ul class="nav nav-underline">
                        <Tab tab={"Questions"} selected={true} setCTab={setCurrTab}/>
                    </ul>
                    <ul class="nav nav-underline">
                        <Tab tab={"Feedback"} selected={true} setCTab={setCurrTab} disabled={true}/>
                    </ul>
                    <ul class="nav nav-underline">
                        <Tab tab={"Act"} selected={true} setCTab={setCurrTab} disabled={true}/>
                    </ul>
                </div>
            }

            <div style={{
                width: '100%', height: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                alignItems: 'center', overflowY: 'auto', borderTop: '1px solid gr'
            }}>
                {currTab === "Questions" && <QuestionsPage />}
                {currTab === "Feedback" && <FeedbackPage />}
                {currTab === "Act" && <ActPage />}

                {(feedback.email && feedback.slack && feedback.type === "Feedback") && <FeedbackOverlay /> }
                {(feedback.email && feedback.slack && feedback.type === "Act") && <ActOverlay /> }
                {(feedback.type === "Vendor") && <VendorOverlay /> }
            </div>

        </div>
    );
};
