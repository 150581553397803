import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { Section } from './Section';

export const Left = () => {
    const {requirements} = useContext(AppContext);

    return (
        <div style={{width: '70%', height: '100%', overflowY:'auto', }}>
            {requirements.requirements.sections.map((section, sectionIndex) => {
                const title = section.sectionTitle;

                const subsections = section.subsections.map((subSection, subIndex) => ({
                    title: subSection.subsectionTitle,
                    items: subSection.items
                }));
                return (
                    <Section
                        key={sectionIndex}
                        title={title}
                        subsections={subsections}
                    />
                );
            })}
        </div>
    );
};
